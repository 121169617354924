import React, { useState } from 'react';
import { createClient } from '@supabase/supabase-js'

const SignUpForm = () => {
  const [formData, setFormData] = useState({
    prenume: '',
    nume: '',
    gen: '',
    statusRelational: '',
    email: '',
  });

  const [isSent, setIsSent] = useState(false)

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Create a single supabase client for interacting with your database
    const supabase = createClient('https://yxkxwciuwnbzqvgtxsqc.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Inl4a3h3Y2l1d25ienF2Z3R4c3FjIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjM3NTMzMTQsImV4cCI6MjAzOTMyOTMxNH0.kkwxzvvq-ntY8NE1zly5A3I155-ulHXRi-PItljJdzw')
    try {
      const { error } = await supabase
        .from('CHEATERS')
        .insert({ date: JSON.stringify(formData) })

      setIsSent(true)

    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  const renderSuccess = () => <div className='text-center'>Ati fost inscris pe lista de asteptare!</div>

  const renderForm = () => {
    return (
      <form onSubmit={handleSubmit} className="w-25 mx-auto mb-5">
        <div className="mb-3">
          <label htmlFor="prenume" className="form-label">Prenume</label>
          <input
            type="text"
            className="form-control"
            id="prenume"
            name="prenume"
            value={formData.prenume}
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-3">
          <label htmlFor="nume" className="form-label">Nume</label>
          <input
            type="text"
            className="form-control"
            id="nume"
            name="nume"
            value={formData.nume}
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-3">
          <label htmlFor="gen" className="form-label">Sex</label>
          <select
            className="form-select"
            id="gen"
            name="gen"
            value={formData.gen}
            onChange={handleChange}
            required
          >
            <option value="">Alege...</option>
            <option value="Masculin">Barbat</option>
            <option value="Feminin">Femeie</option>
            <option value="Altul">Altul</option>
          </select>
        </div>

        <div className="mb-3">
          <label htmlFor="statusRelational" className="form-label">Status Relațional</label>
          <select
            className="form-select"
            id="statusRelational"
            name="statusRelational"
            value={formData.statusRelational}
            onChange={handleChange}
            required
          >
            <option value="">Alege...</option>
            <option value="Singur">Singur</option>
            <option value="Într-o relație">Într-o relație</option>
            <option value="Logodit">Logodit</option>
            <option value="Căsătorit">Căsătorit</option>
          </select>
        </div>

        <div className="mb-3">
          <label htmlFor="email" className="form-label">Email</label>
          <input
            type="email"
            className="form-control"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>

        <button type="submit" className="btn btn-primary">Înscrie-ma</button>
      </form>
    )
  }

  return (
    <div>
        { isSent ? renderSuccess() : renderForm() }
      
    </div>
  );
};

export default SignUpForm;
