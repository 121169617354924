import React from "react";
import "./Main.css";
import SignUpForm from "./SignUpForm";

const Main = () => {
  return <div className="main-page mt-5">
    <h1>Infidelitate Discreta</h1>
    <div className="row m-5">
        <div className="col-3">
            <img src="./woman.png"></img>
        </div>
        <div className="mx-auto col-6">
            <img src="./center.webp"></img>
        </div>
        <div className="col-3">
            <img src="./man.png"></img>
        </div>
    </div>

    <div className="row m-5">
        <div className="col-3">
   
        </div>

        <div className="col-6">
        Trăiește Fanteziile Tale În Secret  <br />

        Descoperă o comunitate discretă unde dorințele tale ascunse prind viață. <br />

        Intră în lumea noastră și conectează-te cu alții care înțeleg nevoia de a păstra aparențele, dar și de a trăi momente intense. Siguranța și confidențialitatea sunt prioritatea noastră.  <br />

        E timpul să te lași purtat de pasiune, fără limite.  <br />

            <h3 className="mt-3">Inscrie-te acum pentru a fi anuntat cand primul site pentru aventuri extra-conjugale din Romania va fi lansat in Septembrie 2024!</h3>
        </div>

        <div className="col-3">

        </div>
    </div>

    <div className="row mt-5">
        <SignUpForm></SignUpForm>
    </div>
  </div>;
};

export default Main;